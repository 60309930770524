


























































import {Vue,Component} from 'vue-property-decorator';
import {DespachoKTBVerde,DespachoKTBVerdeDetalle,ObtenerDespachoKtb,RechazoKtb,DespachoKTBRechazo, Calificacion,RegistrarDespacho} from '@/entidades/MaderaVerde/KtbDespacho';
import {Action, Mutation, State} from 'vuex-class';
import { Planta } from '@/entidades/Maestro/Planta';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
import { Pallet, RecepcionGenerica } from '@/entidades/Calificacion/pallet';
import { Defecto } from '@/entidades/Maestro/Ktb/Defecto';
@Component({
    name : 'RecepcionKtb',
    components:{
         'tabla' : () =>import('@/components/General/TablaGeneralFinal.vue'),
         'confirmarMotivo' : () => import('@/components/Base/FormularioMotivo.vue'),
         'alertaMensaje' : () => import('@/components/Confirmacion.vue'),
         'calificarMadera' :() => import('@/components/KTB/Operacion/Calificar.vue')
    }
})
export default class RecepcionKtb extends Vue 
{
    //setDespachoCalificarLimpiar
    //setDespachoDelete
    estado:Array<string> = ['C','S','P','A'];
    ruta:string ="?Estado=C&Estado=S&Estado=P&Estado=A&PlantaId="
    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>
    @State('defecto',{namespace:'maestroVerde'}) listadoDefecto!:Array<Defecto>;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @State('usuarioId',{namespace: 'authModule'}) usuarioId!:string;
    @State('despachoVerde',{namespace: 'verdeModule'}) despachoVerde!:[];
    @State('despachoCalificado',{namespace: 'verdeModule'}) despachoCalificado!:[];
    @Mutation('setDespacho',{namespace:'verdeModule'}) addDespachos!:Function;
    @Mutation('setDespachoCalificar',{namespace:'verdeModule'}) addCalificar!:Function;
    @Mutation('setDespachoCalificarLimpiar',{namespace:'verdeModule'}) removeDespachoCalificar!:Function;
    @Mutation('setDespachoDelete',{namespace:'verdeModule'}) removerDespacho!:Function;
    @Mutation('setDespachoCalificarItem',{namespace:'verdeModule'}) removerCalificadoItem!:Function;
    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Fecha",sortable:true,value:'fechaDespacho'},
            {text:"Fecha Tumba",sortable:true,value:'fechaTumba'},
            {text:"# Despacho",sortable:true,value:'numeroDespacho'},
            {text:"Tipo Madera",sortable:true,value:'tipoMaderaName'},
            {text:"Hacienda",sortable:true,value:'haciendaName'},
            {text:"Año Cultivo",sortable:false,value:'anioCultivo'},
            {text:"T2K",sortable:false,value:'t2K'},
            {text:"Volumen",sortable:false,value:'volumen'},
            {text:"Accion",sortable:false,value:'actions'}                       
    ];
    headerDetalle:{text:string,sortable:boolean,value:string}[] =
    [
        {text:"# Pallet",sortable:true,value:'palletId'},
        {text:"Espesor",sortable:true,value:'espesorName'},
        {text:"Largo",sortable:true,value:'largoName'},
        {text:"Ancho",sortable:true,value:'anchoId'},
        {text:"Material",sortable:true,value:'material'},
        {text:"Factor Hueco",sortable:true,value:'factorHueco'},
        {text:"# Plantilla",sortable:true,value:'numeroPlantilla'},
        {text:"Volumen",sortable:true,value:'volumen'}                  
    ];
    detalleItem:Array<DespachoKTBVerdeDetalle> = [];

    listado:Array<DespachoKTBVerde> =[];
    itemTmp?:DespachoKTBVerde;
    nameComponent:string="";
    dialogComponente:string="";
    modalDetalle:boolean=false;
    modalDialog:boolean =false;
    mensaje:string="";
    recepcionGenerica?:RecepcionGenerica;
    openSync()
    {
        if(this.despachoCalificado.length >0)
        {
            //Validaciones
            this.mensaje = "Ud tiene Despacho pendiente de enviar, si actualiza la informacion, antes de enviarlo,se borraran automaticamente";
            this.dialogComponente = "alertaMensaje";
            this.modalDialog = true;
        }
        else
        {
            this.syncData();
        }
       
        
    }
    async syncData()
    {
        await this.obtenerListado();
        if(this.listado.length > 0)
        {
            this.addDespachos(this.listado);
            this.removeDespachoCalificar();
        }
    }

    confirmar(descripcion:string)
    {
        if(this.dialogComponente == "confirmarMotivo")
        {
            this.confirmarRechazo(descripcion);
        }
        else if(this.dialogComponente == "alertaMensaje")
        {
            this.syncData();
            this.limpiar();
        }
    }
    async obtenerListado()
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion...Espere por favor"));
            var rsp = await ObtenerDespachoKtb(this.plantas[0].plantaId,this.ruta);
            console.log(rsp);
            if(rsp.isSuccess == true)
            {
                this.listado = rsp.isResult;
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",2000));
            }
        }
        catch(error)
        {
            console.log(error);
        }
        finally 
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    calificar(item:DespachoKTBVerde)
    {
        this.itemTmp = item;
        var pallet:Array<Pallet>=[];
        console.log(item);
        item.detalles.forEach(t=> {
            pallet.push(new Pallet(t.palletId,t.largoId,t.largoName,t.espesorId,t.espesorName,t.volumen,t.pie,t.pulgada,t.palletId));
        })
        this.recepcionGenerica=new RecepcionGenerica(item.despachoId,item.volumen,pallet);
        console.log(this.recepcionGenerica);
        this.nameComponent = "calificarMadera"
        this.modalDetalle = true;
      
    }
    async calificarResponse(calificacion:Calificacion,verificador:number)
    {
        if(this.itemTmp != undefined)
        {
            this.itemTmp.usuarioId = this.usuarioId;
            this.itemTmp.calificacion = calificacion
            this.itemTmp.volumenRecibido = Number(calificacion.volumen);
            this.itemTmp.volumenDescontado = this.itemTmp.volumen - Number(calificacion.volumen);
        }
        if(verificador == 1)
        {
            this.addCalificar(this.itemTmp);
            this.limpiar();
        }
        else
        {
            try
            {
                this.changeLoading(new Loading(true,"Cargando informacion...Espere por favor"));
                var rsp = await RegistrarDespacho(this.itemTmp!);
                if(rsp.isSuccess == true)
                {
                    this.removerDespacho(this.itemTmp?.despachoId);
                    this.changeAlerta(new Alerta(rsp.isMessage,true,"primary",3000))
                    this.limpiar();
                    this.openSync();
                }
                else
                {
                    this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000))
                    return;
                }
            }
            catch(error)
            {
                //@ts-ignore
                this.changeAlerta(new Alerta(error,true,"danger",3000))
            }
            finally
            {
                this.changeLoading(new Loading(false,""));
            }
            
            //vamos a enviar al servidor a registrar la informacion respectiva
        }
       
    }

    async enviarDespacho(item:DespachoKTBVerde)
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion...Espere por favor"));
            var rsp = await RegistrarDespacho(item);
            if(rsp.isSuccess === true)
            {
                this.removerCalificadoItem(item.despachoId);
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
                return;
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000))
                return;
            }
        }
        catch(error)
        {
            //@ts-ignore
            this.changeAlerta(new Alerta(error,true,"danger",3000))
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    verDetalle(item:DespachoKTBVerde)
    {
        this.detalleItem = item.detalles;
        this.nameComponent = "tabla";
        this.modalDetalle = true;
    }
    rechazarDespacho(item:DespachoKTBVerde)
    {
        this.itemTmp = item;
        this.dialogComponente = "confirmarMotivo";
        this.mensaje = `Ingrese el motivo,por el cual esta rechazando el despacho ${item.numeroDespacho}`;
        this.modalDialog = true;
    }
    async confirmarRechazo(descripcion:string)
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion...Espere por favor"));
            var despachoId= this.itemTmp == undefined ? 0 :this.itemTmp.despachoId;
            var rsp = await RechazoKtb(new DespachoKTBRechazo(despachoId,this.usuarioId,descripcion));
            if(rsp.isSuccess == true)
            {
                this.removerDespacho(this.itemTmp?.despachoId);
                 this.changeAlerta(new Alerta(rsp.isMessage,true,"primary",2000));
                 this.limpiar();
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",2000));
            }
        }
        catch(error)
        {
            console.log(error);
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }

    }
    limpiar()
    {
        this.listado = [];
        this.modalDetalle = false;
        this.dialogComponente = "";
        this.itemTmp = undefined;
        this.modalDialog = false;
        this.nameComponent = "";
        //this.obtenerListado();
    }

    get propiedades()
    {
        if(this.nameComponent == "tabla")
        {
            return {'headers' : this.headerDetalle, 'busqueda' :false, 'items' : this.detalleItem};
        }
        else if(this.nameComponent == "calificarMadera")
        {
            return {'items':this.recepcionGenerica,'listadoDefecto':this.listadoDefecto,'proceso':'VERDE'};
        }
    }

    get propiedadDialog()
    {
        if(this.dialogComponente == "confirmarMotivo")
        {
            return {'mensaje' : this.mensaje,'modal' : this.modalDialog};
        }
        else if(this.dialogComponente =="alertaMensaje")
        {
            return {'mensaje' : this.mensaje,'modal' : this.modalDialog};
        }
    }

    async mounted() 
    {
       // await this.obtenerListado();
    }
}
